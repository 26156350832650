import ICompetition from "../Interfaces/Competition";
import { useState } from "react";
import * as Yup from "yup";
import TournamentService from "../../services/TournamentService";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import Modal from "./Modal";
import FormikControl from "../Formik/FormikControl";
import Marquee from "react-text-marquee";
import { Link } from "react-router-dom";

interface IFormModel {
  tournamentList: string;
}

const initialValues: IFormModel = {
  tournamentList: "",
};

const validationSchema = Yup.object({
  tournamentList: Yup.string().required("Required"),
});

type Props = {
  competition: ICompetition;
};

const CompetitionTile = ({ competition }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [imageError, setImageError] = useState(false);

  const onSubmit = (values: IFormModel) => {
    const arr = values.tournamentList.split(/[^a-zA-Z0-9]+/);
    let data = {
      tournaments: arr,
    };
    TournamentService.updateCompetition(data, competition.id)
      .then((response) => {
        toast.success("Competition update");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="tournament-tile">
      {/*onClick={() => setShowModal(true)}>*/}
      <Link to={`/competitions/${competition.id}/overview`}>
        <div className="tournament-tile-image">
          {competition.thumbnail && !imageError && (
            <img
              src={competition.thumbnail}
              alt={competition.title}
              onError={() => setImageError(true)}
            />
          )}
          {competition.banner && !competition.thumbnail && (
            <img
              src={competition.banner}
              alt={competition.title}
              onError={() => setImageError(true)}
            />
          )}
        </div>
        <div className="tournament-tile-content columns">
          <div className="column col-12">
            <span className="skp-text-large skp-text-primary">
              <Marquee text={competition.name} />
            </span>
          </div>
        </div>
      </Link>

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title={competition.name}
        size="medium"
      >
        <div className="column col-12">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, errors, touched, isValid }) => (
              <Form className="columns">
                <div className="column col-12">
                  <FormikControl
                    control="input"
                    label="Tournament List"
                    name="tournamentList"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                <div className="space-divider"></div>
                <div className="column col-12 text-right">
                  <button type="submit" className="skp-btn skp-btn-primary">
                    Update
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default CompetitionTile;
