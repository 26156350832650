import React, { useEffect, useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { useParams } from "react-router";
import TeamService from "../../../../services/TeamService";
import { Link, useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
// import OverviewTab from "./TeamTabs/OverviewTab";
import MembersTab from "../BrandTabs/MembersTab";
import PendingsTab from "./BrandSettingsTabs/PendingsTab";
import Modal from "../../../UiLibrary/Modal";
import UploadImageBox from "../../../UiLibrary/UploadImageBox/UploadImageBox";
import ImageService from "../../../../services/ImageService";
import { toast } from "react-toastify";
import UploadImageButton from "../../../UiLibrary/UploadImageButton";
import { UserContext } from "../../../Context/UserContext";

import LoginOrRegisterModal from "../../../Modals/LoginOrRegisterModal/LoginOrRegisterModal";
import constant from "../../../../constants";
import AboutTab from "./BrandSettingsTabs/AboutTab";
import TeamInfo from "./BrandSettingsTabs/TeamInfo";
import IFileBase64Image from "../../../Interfaces/FileBase64Image";
import IBrand from "../../../Interfaces/Brand";
import { useTranslation } from "react-i18next";
import TeamBrandLogo from "../../../UiLibrary/TeamBrandLogo";
import CreateNewsTab from "../BrandTabs/News/CreateNewsTab";
import INews from "../../../Interfaces/News";
import NewsService from "../../../../services/NewsService";

const BrandSettings = () => {
  let navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [brandInfo, setBrandInfo] = useState<IBrand | undefined>();
  const [showUploadModal, setShowUploadModal] = useState<{
    show: boolean;
    type?: string | undefined;
  }>({
    show: false,
    type: undefined,
  });
  const [imageToUpload, setImageToUpload] = useState<IFileBase64Image>();
  const [loading, setLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let params = useParams<{ id: string; tab: string; slug: string }>();
  const brandId = params.id;
  const brandSlug = params.slug;

  const currentTab = params.tab;
  const tabs = [
    { name: t("tabs.about"), url: "about" },
    { name: t("tabs.information"), url: "info" },
    { name: t("tabs.members"), url: "members" },
    { name: t("tabs.pendings"), url: "pendings" },
    { name: t("tabs.news"), url: "news" },
  ];

  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    if (!currentTab) {
      navigate(`/brands/${brandSlug}/settings/about`);
    }
    TeamService.getBrand(brandSlug!).then((response) => {
      setBrandInfo(response.data);
    });
  }, [brandSlug, currentTab, navigate]);

  const joinTeamUrl = `/brands/join-brand/${brandSlug}`;

  const uploadImage = (image: any) => {
    setImageToUpload(image);
  };

  const updateImage = (type: string | undefined) => {
    if (!type || !brandInfo || !imageToUpload) {
      return;
    }
    setLoading(true);
    ImageService.uploadImage(imageToUpload).then((response) => {
      let data = {} as any;
      data[type] = ImageService.generatePublicUrl(response.data);
      TeamService.updateBrand(brandInfo.id, data).then((Rteam) => {
        toast.success(t("toast.brandUpdatedSuccess"));
        setLoading(false);
        setBrandInfo(Rteam);
        setShowUploadModal({ show: false });
      });
    });
  };

  const deleteTeam = () => {
    setLoading(true);
    TeamService.deleteTeam(brandInfo?.id!)
      .then(() => {
        toast.info(t("toast.brandDeletedSuccess"));
        navigate("/brands");
        setShowDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        setShowDeleteModal(false);
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const updateTeam = (data: any) => {
    setLoading(true);
    TeamService.updateBrand(brandInfo?.id!, data)
      .then((Rteam) => {
        setLoading(false);
        setBrandInfo(Rteam);
        toast.info(t("toast.brandUpdatedSuccess"));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const createNews = (news: INews) => {
    setLoading(true);
    NewsService.createNews(news)
      .then((Rnews) => {
        setLoading(false);
        toast.info(t("toast.brandUpdatedSuccess"));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <>
      <Scrollbars
        renderView={(props: any) => <div {...props} id="scrollableDiv" />}
      >
        <div className="skp-page-with-image-banner">
          {/* IMAGE BANNER */}
          {user?.id === brandInfo?.owner && (
            <button
              onClick={() => setShowUploadModal({ show: true, type: "banner" })}
              className="upload-banner-btn skp-btn skp-btn-secondary skp-text-default"
            >
              <i className="las la-camera"></i> {t("component.uploadBanner")}
            </button>
          )}
          <div className="skp-image-banner">
            {brandInfo && brandInfo?.banner && !imageError && (
              <img
                alt="banner"
                src={brandInfo?.banner}
                onError={() => setImageError(true)}
              />
            )}
            <div className="transparent-black"></div>
          </div>
          {/* END IMAGE BANNER */}

          {/* PAGE TOP */}
          <div className="container page-top">
            {/* Page Top */}
            <div className="columns page-top">
              <div className="column col-3 profile-picture text-center col-xs-12 text-center-xs">
                <TeamBrandLogo team={brandInfo}>
                  <>
                    {user?.id === brandInfo?.owner && (
                      <UploadImageButton
                        onClick={() =>
                          setShowUploadModal({ show: true, type: "logo" })
                        }
                      />
                    )}
                  </>
                </TeamBrandLogo>
              </div>
              <div className="column col-9 col-xs-12 text-center-xs">
                <div className="columns">
                  <div className="column col-12">
                    <span className="skp-text-label skp-text-light">
                      {t("brands.config.brandSettings")}
                    </span>{" "}
                    <br></br>
                    <h1 className="skp-text-primary skp-heading-no-margin">
                      {brandInfo?.name}
                    </h1>
                    <Link
                      to={`/brands/${brandSlug}/overview`}
                      className="skp-link skp-text-small"
                    >
                      <i className="las la-arrow-left"></i>{" "}
                      {t("brands.config.viewBrand")}
                    </Link>
                  </div>
                  <div className="column col-12">
                    <div className="space-divider-20"></div>
                    <div className="columns skp-text-primary">
                      <div className="column col-8">
                        {t("brands.config.member", {
                          count: brandInfo?.members.length,
                        })}
                        {" - "}
                        <Link to={joinTeamUrl}>
                          {t("brands.main.join", { brand: brandInfo?.name })}
                        </Link>
                      </div>
                      <div className="column col-4 text-right">
                        <button
                          className="skp-btn skp-btn-alert"
                          onClick={() => setShowDeleteModal(true)}
                        >
                          {t("brands.config.deleteBrand")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END PAGE TOP */}
          <div className="space-divider-5"></div>
          {/* TABS HEADER */}
          <div className="container tabs-header">
            <div className="columns">
              <div className="column col-12">
                <ul className="tab skp-text-light">
                  {tabs.map((tab) => (
                    <li
                      key={tab.name}
                      className={
                        currentTab === tab.url ? "tab-item active" : "tab-item"
                      }
                    >
                      <Link
                        to={`/brands/${brandSlug}/settings/${tab.url}`}
                        className={`${
                          tab.url === tabs[3].url &&
                          brandInfo?.pendings &&
                          brandInfo?.pendings?.length > 0 &&
                          "badge"
                        } ${currentTab === tab.url && "active"}`}
                        data-badge={brandInfo?.pendings?.length}
                      >
                        {tab.name}{" "}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* END TABS HEADER */}
          {/* TABS BODY */}
          <div className="container tabs-body">
            {currentTab === tabs[0].url && (
              <AboutTab
                brand={brandInfo}
                onSave={updateTeam}
                loading={loading}
              />
            )}
            {currentTab === tabs[1].url && (
              <TeamInfo
                team={brandInfo}
                user={user}
                onSave={updateTeam}
                loading={loading}
              />
            )}
            {currentTab === tabs[2].url && (
              <>
                <MembersTab
                  brand={brandInfo}
                  setBrand={(brand: IBrand) => setBrandInfo(brand)}
                  doLogin={() => setShowLoginModal(true)}
                />
              </>
            )}
            {currentTab === tabs[3].url && (
              <PendingsTab
                brand={brandInfo}
                setBrand={(brand: IBrand) => setBrandInfo(brand)}
                doLogin={() => setShowLoginModal(true)}
              />
            )}
            {currentTab === tabs[4].url && (
              <CreateNewsTab
                loading={loading}
                onSave={(news: INews) => createNews(news)}
                brandId={brandInfo?.id}
              />
            )}
          </div>
          {/* END TABS BODY */}
        </div>
      </Scrollbars>
      <Modal
        show={showUploadModal.show}
        title={t("titles.uploadImage")}
        size="large"
        onClose={() => {
          setShowUploadModal({ show: false });
        }}
      >
        <div className="container">
          {showUploadModal.type === "banner" && (
            <div className="columns">
              <div className="space-divider-20"></div>
              <div
                className="column col-12"
                style={{ height: 250, border: "2px dashed var(--gray-1)" }}
              >
                <UploadImageBox
                  onUpload={uploadImage}
                  onRemovePreview={() => setImageToUpload(undefined)}
                />
              </div>
              <div className="space-divider-20"></div>
              <div className="column col-12 text-right">
                <button
                  onClick={() => updateImage(showUploadModal.type)}
                  disabled={!imageToUpload?.file || loading}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? (
                    <span className="loading"></span>
                  ) : (
                    t("common.upload")
                  )}
                </button>
              </div>
            </div>
          )}

          {showUploadModal.type === "logo" && (
            <div className="columns">
              <div className="space-divider-20"></div>
              <div className="column col-12 text-center">
                <div className="upload-logo-wrapper">
                  <UploadImageBox
                    onUpload={uploadImage}
                    onRemovePreview={() => setImageToUpload(undefined)}
                  />
                </div>
              </div>

              <div className="space-divider-20"></div>
              <div className="column col-12 text-right">
                <button
                  onClick={() => updateImage(showUploadModal.type)}
                  disabled={!imageToUpload?.file || loading}
                  className="skp-btn skp-btn-primary"
                >
                  {loading ? (
                    <span className="loading"></span>
                  ) : (
                    t("common.upload")
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        show={showLoginModal}
        size="small"
        onClose={() => setShowLoginModal(false)}
      >
        <LoginOrRegisterModal
          message={constant.MESSAGES.LOGIN.TEAM_PLAYERS}
          onClose={() => setShowLoginModal(false)}
        />
      </Modal>

      <Modal
        show={showDeleteModal}
        title={t("brands.config.deleteBrand")}
        onClose={() => setShowDeleteModal(false)}
        size="small"
        confirmation={t("messages.delete")}
        onConfirm={deleteTeam}
        loading={loading}
      >
        <>
          <div className="space-divider-20"></div>
          <div className="skp-text-primary text-center">
            {t("brands.config.doDeleteBrand")}{" "}
            <span className="skp-text-accent">{brandInfo?.name}</span> ?
          </div>
          <div className="space-divider-20"></div>
        </>
      </Modal>
    </>
  );
};

export default BrandSettings;
