import { Link } from "react-router-dom";
import UserProfile from "../../../UiLibrary/UserProfile";
import "./BrandStyle.scss";

type BrandLeaderboardRowProps = {
  player: any;
  isEdit: boolean;
  reportScore: (newScore: number, newWins: number) => void;
};

const BrandLeaderboardRow = ({
  player,
  isEdit,
  reportScore,
}: BrandLeaderboardRowProps) => {
  return (
    <div className="columns">
      <div className="column col-4 col-xs-2 mb-2">
        <Link to={`/users/${player.playerId}/overview`}>
          <UserProfile userId={player.playerId}></UserProfile>
        </Link>
      </div>
      <div className="column col-4 mb-2 col-xs-6 brand-row-text">
        {player.position}
      </div>
      <div className="column col-2 mb-2 brand-row-text">
        {isEdit ? (
          <input
            type="number"
            value={player.score}
            onChange={(e) => reportScore(Number(e.target.value), player.wins)}
          />
        ) : (
          player.score
        )}
      </div>
      <div className="column col-2 mb-2 hide-xs brand-row-text">
        {isEdit ? (
          <input
            type="number"
            value={player.wins}
            onChange={(e) => reportScore(player.score, Number(e.target.value))}
          />
        ) : (
          player.wins
        )}
      </div>
    </div>
  );
};

export default BrandLeaderboardRow;
