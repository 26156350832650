import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ITournament from "../../Interfaces/Tournament";
import TournamentTile from "../../UiLibrary/TournamentTile";
import TournamentService from "../../../services/TournamentService";

type Props = {
  competitionId: string | undefined;
};

const TournamentTab = (props: Props) => {
  const { competitionId } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [tournaments, setTournamentList] = useState<Array<ITournament>>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (competitionId) {
      TournamentService.getTournamentsByCompetition(competitionId).then(
        (tournaments) => {
          setTournamentList(tournaments);
        }
      );
    }
  }, [competitionId]);

  return (
    <>
      {/* Tiles */}
      {tournaments.length > 0 &&
        tournaments.map((tournament) => (
          <div className="column col-3 col-xs-12" key={tournament.id}>
            <TournamentTile tournament={tournament}></TournamentTile>
          </div>
        ))}
      {tournaments.length === 0 && (
        <div className="column col-12">
          <h6 className="skp-text-primary">{t("home.noTournaments")}</h6>
        </div>
      )}

      <div className="space-divider"></div>
    </>
  );
};

export default TournamentTab;
