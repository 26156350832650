import HttpService from "./HttpService";
import constant from "../constants";
import qs from "query-string";
import INews from "../components/Interfaces/News";
import { setupCache } from "axios-cache-adapter";

let _axios = HttpService.getAxiosClient();

const createNews = (data:any) => {
    const url = `${constant.API_URL}/news`;
    return _axios({
        method: "post",
        url: url,
        data: data,
    }).then((response) => {
        return response;
    });
};

const getAllNews = (queryParam?:any):Promise<Array<INews>> => {
    let url = `${constant.API_URL}/news`;
    if (queryParam) {
        url += `?${qs.stringify(queryParam)}`;
    }
  
    return _axios({
        method: "get",
        url: url
      }).then((res) => {
        console.log(res)
        return res.data.news;
    });
};

const getBrandNews = (brandId: string, queryParam?:any):Promise<Array<INews>> => {
    let url = `${constant.API_URL}/news/brand/${brandId}`;
    if (queryParam) {
        url += `?${qs.stringify(queryParam)}`;
    }
  
    return _axios({
        method: "get",
        url: url
      }).then((res) => {
        console.log(res)
        return res.data.news;
    });
};
  
const getNewsById = (newsId:string): Promise<INews> => {
    let url = `${constant.API_URL}/news/${newsId}`;
    
    return _axios.get(url).then((res) => {
      return res.data;
    });
};

const updateNews = (newsId:string, data:any):Promise<INews> => {
    const url = `${constant.API_URL}/news/${newsId}`;
    return _axios({
        method: "put",
        url: url,
        data: data
    }).then((response) => {
        return response.data;
    });
}
  
const deleteNews = (newsId:string) => {
    const url = `${constant.API_URL}/news/${newsId}`;
    return _axios({
        method: "delete",
        url: url
    }).then((response) => {
        return response;
    });
}

const NewsService = {
    createNews,
    getAllNews,
    getBrandNews,
    getNewsById,
    updateNews,
    deleteNews
};
  
export default NewsService;