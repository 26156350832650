import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../../Formik/FormikControl";
import ErrorLabel from "../../../../Forms/ErrorLabel";
import INews from "../../../../Interfaces/News";
import TextEditor from "../../../../UiLibrary/RichTextEditor";
import IFileBase64Image from "../../../../Interfaces/FileBase64Image";
import ImageService from "../../../../../services/ImageService";
import { toast } from "react-toastify";
import UploadButton from "../../../../UiLibrary/UploadButton/UploadButton";
import "./CreateNewsTab.css";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  date: Yup.string().required("Date is required"),
  imageUrl: Yup.string().url("Invalid URL").required("Image URL is required"),
  text: Yup.string().required("Content is required"),
});

interface Props {
  loading: boolean;
  news?: INews;
  brandId?: string;
  onSave: (values: INews) => void;
}

const CreateNewsTab = (props: Props) => {
  const { loading, news, brandId } = props;
  const { t } = useTranslation();

  const initialValues: INews = {
    title: news?.title || "",
    date: news?.date || "",
    imageUrl: news?.imageUrl || "",
    text: news?.text || "",
    owner: brandId,
  };

  const uploadPicture = (
    image: IFileBase64Image,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    ImageService.uploadImage(image)
      .then((response) => {
        const imageUrl = ImageService.generatePublicUrl(response.data);
        setFieldValue("imageUrl", imageUrl);
        toast.success(t("toast.imageUploadSuccess"));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const onSubmit = (values: INews) => {
    props.onSave(values);
  };

  return (
    <div className="container">
      <div className="space-divider-20"></div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ errors, touched, isValid, values, setFieldValue, submitForm }) => (
          <Form className="columns">
            <div className="column col-12">
              <h5 className="skp-text-primary">{t("news.create")}</h5>
            </div>
            {/* Titolo */}
            <div className="column col-12">
              <FormikControl
                control="input"
                label={t("news.titleLabel")}
                name="title"
                placeholder={t("news.titlePlaceholder")}
              />
            </div>

            {/* Data con Selettore di Data/Orario */}
            <div className="column col-12">
              <FormikControl
                control="date"
                label={t("news.date")}
                name="date"
                dateFormat="dd/MM/yyyy h:mm aa"
                showTimeSelect
              />
            </div>

            <div className="space-divider"></div>

            {/* Caricamento Immagine */}
            <div className="column col-12">
              <h5 className="skp-text-primary">{t("news.image")}</h5>
            </div>
            <div className="column col-12">
              <div className="columns">
                <div className="column col-10">
                  <FormikControl
                    control="input"
                    label={t("news.imageLabel")}
                    name="imageUrl"
                    placeholder={t("news.imagePlaceholder")}
                  />
                </div>
                <div className="column col-2 skp-upload-news-button-position">
                  <UploadButton
                    type="banner"
                    loading={loading}
                    onUpdateImage={(image) =>
                      uploadPicture(image, setFieldValue)
                    }
                  >
                    <button className="skp-btn skp-btn-secondary skp-text-default skp-upload-news-button">
                      <i className="las la-camera"></i> {t("news.uploadImage")}
                    </button>
                  </UploadButton>
                  {errors.imageUrl && touched.imageUrl && (
                    <ErrorLabel>{errors.imageUrl}</ErrorLabel>
                  )}
                </div>
              </div>
            </div>

            <div className="space-divider"></div>

            {/* Contenuto News */}
            <div className="column col-12">
              <h5 className="skp-text-primary">{t("news.content")}</h5>
              <TextEditor
                onSave={(text) => {
                  setFieldValue("text", text);
                  submitForm();
                }}
                initialValue={values.text}
                isSaving={loading}
                hideDeleteAll={true}
              />
              {errors.text && touched.text && (
                <ErrorLabel>{errors.text}</ErrorLabel>
              )}
            </div>
            <div className="space-divider-20"></div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateNewsTab;
