import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../Context/UserContext";
import ITournament from "../../../Interfaces/Tournament";
import ITournamentPointSystem from "../../../Interfaces/TournamentPointSystem";
import { useTranslation } from "react-i18next"; // Importazione del hook per le traduzioni

type DrawInputProps = {
  element: any;
  disabled?: boolean;
  defaultPS: boolean;
  onInputchange: (event: any, key: string, index: any) => void;
  onDeleteRow: (index: any) => void;
};

const DrawInput = (props: DrawInputProps) => {
  const { element, disabled, defaultPS } = props;
  const { t } = useTranslation(); // Uso del hook per traduzioni
  return (
    <>
      <div className="column col-5">
        <div className="skp-form-control">
          <input
            disabled={disabled}
            name="position"
            type="number"
            value={element.key}
            onChange={(e) => props.onInputchange(e, "key", element.index)}
            placeholder={t("pointSystem.placement")} // Traduzione
          />
        </div>
      </div>
      <div className="column col-5">
        <div className="skp-form-control">
          <input
            disabled={disabled}
            name="points"
            type="number"
            step={defaultPS ? "1" : "0.1"} // Step dinamico in base a defaultPS
            value={element.value}
            onChange={(e) => props.onInputchange(e, "value", element.index)}
            placeholder={defaultPS ? t("pointSystem.points") : "Moltiplicatore"} // Traduzione
          />
        </div>
      </div>
      <div className="column col-2 flex-centered">
        {!disabled && (
          <button
            className="skp-btn skp-btn-secondary skp-text-xlarge"
            style={{ width: "100%" }}
            onClick={() => props.onDeleteRow(element.index)}
          >
            <i className="las la-trash"></i> {t("common.delete")}{" "}
            {/* Traduzione */}
          </button>
        )}
      </div>
    </>
  );
};

type Props = {
  tournament: ITournament;
  onSubmit: (pointSystem: { pointSystem: ITournamentPointSystem }) => void;
};

const PointSystemTab = ({ tournament, ...props }: Props) => {
  const { user } = useContext(UserContext);
  const [newList, setNewList] = useState<
    Array<{ key: number | undefined; value: number | undefined; index: number }>
  >([]);

  const pointsPerKill = useRef<HTMLInputElement>(null);
  const [isDefaultPointMode, setIsDefaultPointMode] = useState(true);
  const { t } = useTranslation(); // Hook per le traduzioni

  useEffect(() => {
    let array: Array<{
      key: number | undefined;
      value: number | undefined;
      index: number;
    }> = [];
    let i = 0;

    // Verifica se è presente un sistema di punteggio di tipo "point" o "mult"
    const isMultType = tournament?.pointSystem?.type === "mult";
    setIsDefaultPointMode(!isMultType);

    // Scegli la struttura da utilizzare in base al tipo
    const pointsData = isMultType
      ? tournament?.pointSystem?.multPerPlacement
      : tournament?.pointSystem?.pointsPerPlacement;

    for (let item in pointsData) {
      const objToPush = {
        key: Number(item),
        value: pointsData[Number(item)],
        index: i,
      };
      array.push(objToPush);
      i++;
    }
    setNewList(array);

    // Imposta pointsPerKill se presente e se il proprietario è l'utente corrente
    if (tournament?.owner === user?.id && pointsPerKill?.current) {
      pointsPerKill.current.value =
        tournament?.pointSystem?.pointsPerKill?.toString() || "";
    }
  }, [tournament, user]);

  const savePointSystem = () => {
    let pointSystem: { pointSystem: ITournamentPointSystem } = {
      pointSystem: { type: isDefaultPointMode ? "point" : "mult" }, // Assegna 'point' o 'mult' in base al flag
    };

    if (isDefaultPointMode) {
      // Sistema predefinito
      const obj: { [key: number]: number } = {};
      newList.forEach(
        (item: {
          key: number | undefined;
          value: number | undefined;
          index: number;
        }) => {
          if (item.value !== undefined && item.key !== undefined) {
            obj[item.key] = Number(item.value);
          }
        }
      );

      // Assegna il sistema di punti predefinito
      pointSystem.pointSystem.pointsPerPlacement = obj;
      pointSystem.pointSystem.pointsPerKill = Number(
        pointsPerKill?.current?.value
      );
    } else {
      // Sistema con moltiplicatore
      const multObj: { [key: number]: number } = {};
      newList.forEach(
        (item: {
          key: number | undefined;
          value: number | undefined;
          index: number;
        }) => {
          if (item.value !== undefined && item.key !== undefined) {
            multObj[item.key] = Number(item.value);
          }
        }
      );

      // Assegna il sistema con moltiplicatore
      pointSystem.pointSystem.multPerPlacement = multObj;
      pointSystem.pointSystem.pointsPerKill = Number(
        pointsPerKill?.current?.value
      );
    }

    props.onSubmit(pointSystem);
  };

  const inputChange = (event: any, key: string, index: number) => {
    let newArray: Array<any> = [...newList];
    newArray[index][key] = event.target.value;
    setNewList(newArray);
  };

  const deleteRow = (index: number) => {
    let newArray = [...newList];
    newArray.splice(index, 1);
    setNewList(newArray);
  };

  const addPlacement = () => {
    setNewList([
      ...newList,
      { key: undefined, value: undefined, index: newList.length },
    ]);
  };

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-12">
          {/* Checkbox per selezionare il sistema di punteggio */}
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isDefaultPointMode}
              onChange={(e) => setIsDefaultPointMode(e.target.checked)}
            />
            <span className="skp-text-primary" style={{ paddingLeft: 10 }}>
              {t("pointSystem.useDefault")} {/* Traduzione */}
            </span>
          </label>
          <div className="space-divider-20"></div>
          {isDefaultPointMode ? (
            <>
              <h5 className="skp-text-primary float-left">
                {t("pointSystem.placementPoints")} {/* Traduzione */}
              </h5>

              <button
                onClick={savePointSystem}
                className="skp-btn skp-btn-primary float-right"
              >
                {t("common.save")} {/* Traduzione */}
              </button>

              <div className="space-divider-10"></div>

              <div className="skp-card card">
                <div className="columns">
                  <div className="column col-12 card-header">
                    <h6 className="skp-text-primary card-title">
                      {t("pointSystem.pointsPerElimination")} {/* Traduzione */}
                    </h6>
                  </div>
                  <div className="column col-5">
                    <div className="skp-form-control">
                      <input
                        ref={pointsPerKill}
                        type="number"
                        value={tournament?.pointSystem?.pointsPerKill}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-divider-20"></div>
              </div>

              <div className="space-divider-20"></div>
              <div className="skp-card card">
                <div className="columns">
                  <div className="column col-5 card-header">
                    <h6 className="skp-text-primary">
                      {t("pointSystem.placement")}
                    </h6>{" "}
                    {/* Traduzione */}
                  </div>
                  <div className="column col-5 card-header">
                    <h6 className="skp-text-primary">
                      {t("pointSystem.points")}
                    </h6>{" "}
                    {/* Traduzione */}
                  </div>
                  <div className="column col-2"></div>
                  <>
                    {newList.map((element) => (
                      <DrawInput
                        key={element.index}
                        element={element}
                        defaultPS={true}
                        onInputchange={inputChange}
                        onDeleteRow={deleteRow}
                      />
                    ))}

                    <div className="space-divider-20"></div>

                    <button
                      className="skp-btn skp-btn-primary"
                      style={{ width: "100%" }}
                      onClick={addPlacement}
                    >
                      <i className="las la-plus"></i>{" "}
                      {t("pointSystem.addPlacement")} {/* Traduzione */}
                    </button>
                  </>
                </div>
              </div>
            </>
          ) : (
            <>
              <h5 className="skp-text-primary float-left">
                Moltiplicatore per uccisioni
              </h5>

              <button
                onClick={savePointSystem}
                className="skp-btn skp-btn-primary float-right"
              >
                {t("common.save")} {/* Traduzione */}
              </button>

              <div className="space-divider-10"></div>

              <div className="skp-card card">
                <div className="columns">
                  <div className="column col-12 card-header">
                    <h6 className="skp-text-primary card-title">
                      {t("pointSystem.pointsPerElimination")} {/* Traduzione */}
                    </h6>
                  </div>
                  <div className="column col-5">
                    <div className="skp-form-control">
                      <input
                        ref={pointsPerKill}
                        type="number"
                        value={tournament?.pointSystem?.pointsPerKill}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-divider-20"></div>
              </div>

              <div className="space-divider-20"></div>
              <div className="skp-card card">
                <div className="columns">
                  <div className="column col-5 card-header">
                    <h6 className="skp-text-primary">
                      {t("pointSystem.placement")}
                    </h6>{" "}
                    {/* Traduzione */}
                  </div>
                  <div className="column col-5 card-header">
                    <h6 className="skp-text-primary">Moltiplicatore</h6>{" "}
                    {/* Traduzione */}
                  </div>
                  <div className="column col-2"></div>
                  <>
                    {newList.map((element) => (
                      <DrawInput
                        key={element.index}
                        element={element}
                        defaultPS={false}
                        onInputchange={inputChange}
                        onDeleteRow={deleteRow}
                      />
                    ))}

                    <div className="space-divider-20"></div>

                    <button
                      className="skp-btn skp-btn-primary"
                      style={{ width: "100%" }}
                      onClick={addPlacement}
                    >
                      <i className="las la-plus"></i> Aggiungi Moltiplicatore
                    </button>
                  </>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PointSystemTab;
