import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import IBrand from "../../../Interfaces/Brand";
import { toast } from "react-toastify";
import BrandLeaderboardRow from "./BrandLeaderboardRow";

type Props = {
  brand: IBrand | undefined;
  isAdmin: boolean;
};

const BrandLeaderboardTab = (props: Props) => {
  const { brand, isAdmin } = props;
  const { t } = useTranslation(); // Inizializza il traduttore
  const [adminLeaderboard, setAdminLeaderboard] = useState(false);
  const [leaderboard, setLeaderboard] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);

  useEffect(() => {
    if (brand && brand.members) {
      const initialLeaderboard = brand.members.map((memberId, index) => ({
        playerId: memberId,
        position: index + 1,
        score: 0,
        wins: 0,
      }));
      setLeaderboard(initialLeaderboard);
    }
  }, [brand]);

  const handleSort = () => {
    if (leaderboard) {
      const updatedLeaderboard = [...leaderboard];
      const draggedItem = updatedLeaderboard.splice(
        dragItem.current as number,
        1
      )[0];
      updatedLeaderboard.splice(dragOverItem.current as number, 0, draggedItem);

      const reorderedLeaderboard = updatedLeaderboard.map((player, index) => ({
        ...player,
        position: index + 1,
      }));

      setLeaderboard(reorderedLeaderboard);

      dragItem.current = null;
      dragOverItem.current = null;
    }
  };

  const reportScore = (playerId: string, newScore: number, newWins: number) => {
    const updatedLeaderboard = leaderboard.map((player) =>
      player.playerId === playerId
        ? { ...player, score: newScore, wins: newWins }
        : player
    );
    setLeaderboard(updatedLeaderboard);
  };

  const saveLeaderboard = () => {
    setLoading(true);
    /* BrandService.updateBrandLeaderboard(brand?.id!, leaderboard)
      .then(() => {
        toast.info(t("toast.leaderboardUpdatedSuccess"));
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }); */
  };

  return (
    <div className="column col-12">
      <div className="columns">
        <div className="space-divider-20"></div>
        <div className="column col-9">
          <h5 className="skp-text-primary">{t("brands.leaderboard")}</h5>
        </div>
        {isAdmin && (
          <div className="column col-3 text-right">
            <button
              className="skp-btn skp-btn-secondary"
              onClick={() => {
                setAdminLeaderboard(!adminLeaderboard);
                if (adminLeaderboard) saveLeaderboard();
              }}
            >
              {adminLeaderboard
                ? t("common.saveChanges")
                : t("brands.adminLeaderboard")}
            </button>
          </div>
        )}
        <div className="space-divider-20"></div>
      </div>

      <div className="columns">
        <div className="column col-12">
          <div style={{ padding: 10 }} className="flex-centered">
            <div className="column col-4 col-xs-2 mb-2 skp-text-primary">
              {t("common.player")}
            </div>
            <div className="column col-4 mb-2 col-xs-6 skp-text-primary">
              {t("common.position")}
            </div>
            <div className="column col-2 mb-2 skp-text-primary">
              {t("common.score")}
            </div>
            <div className="column col-2 mb-2 hide-xs skp-text-primary">
              {t("common.wins")}
            </div>
          </div>

          <dl className="bordered-list">
            {leaderboard.map((player, index) => (
              <dd key={index} className="columns flex-centered">
                <div
                  className="column col-12"
                  draggable={adminLeaderboard}
                  onDragStart={() => (dragItem.current = index)}
                  onDragEnter={() => (dragOverItem.current = index)}
                  onDragEnd={handleSort}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <BrandLeaderboardRow
                    player={player}
                    isEdit={adminLeaderboard}
                    reportScore={(newScore, newWins) =>
                      reportScore(player.playerId, newScore, newWins)
                    }
                  />
                </div>
              </dd>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default BrandLeaderboardTab;
