import { useTranslation } from "react-i18next";
import ICompetition from "../../Interfaces/Competition";

type Props = {
  competition: ICompetition | undefined;
};

const OverviewTab = (props: Props) => {
  const { competition } = props;
  const { t } = useTranslation();

  return (
    <div className="column col-12 col-xs-12 p-2">
      <div className="columns">
        <div className="skp-card column col-12 p-2">
          <h4 className="skp-text-primary">{t("titles.information")}</h4>
          <h5 className="skp-text-primary">
            {competition ? competition.name : ""}
          </h5>
          <div
            className="skp-text-light p-1"
            dangerouslySetInnerHTML={{
              __html: competition?.description || "",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;
