import ITournament from "../../../../Interfaces/Tournament";
import { useTranslation } from "react-i18next"; // Importa il hook per le traduzioni

type Props = {
  tournament: ITournament | undefined;
};

const PointSystemTab = (props: Props) => {
  const { tournament } = props;
  const { t } = useTranslation(); // Usa il hook per le traduzioni

  const isMultType = tournament?.pointSystem?.type === "mult";

  return (
    <div className="container">
      {tournament && (
        <>
          <div className="space-divider-20"></div>
          <h5 className="skp-text-primary">
            {isMultType
              ? t("pointSystem.multiplierPoints")
              : t("pointSystem.placementPoints")}
          </h5>
          <h6 className="skp-text-light">
            {t("pointSystem.pointsPerElimination")}:{" "}
            {tournament?.pointSystem?.pointsPerKill}
          </h6>
          <div className="skp-table-flex skp-text-primary">
            <div className="skp-table-flex-row skp-text-light">
              <div className="skp-table-flex-cell"></div>
              <div className="skp-table-flex-cell">
                {t("pointSystem.placement")}
              </div>
              <div className="skp-table-flex-cell">
                {isMultType
                  ? t("pointSystem.multiplier")
                  : t("pointSystem.points")}
              </div>
            </div>

            {isMultType
              ? tournament?.pointSystem?.multPerPlacement &&
                Object.keys(tournament?.pointSystem?.multPerPlacement).map(
                  (key) => (
                    <div className="skp-table-flex-row" key={key}>
                      <div className="skp-table-flex-cell"></div>
                      <div className="skp-table-flex-cell">{key}</div>
                      <div className="skp-table-flex-cell">
                        {
                          tournament?.pointSystem?.multPerPlacement![
                            Number(key)
                          ]
                        }
                      </div>
                    </div>
                  )
                )
              : tournament?.pointSystem?.pointsPerPlacement &&
                Object.keys(tournament?.pointSystem?.pointsPerPlacement).map(
                  (key) => (
                    <div className="skp-table-flex-row" key={key}>
                      <div className="skp-table-flex-cell"></div>
                      <div className="skp-table-flex-cell">{key}</div>
                      <div className="skp-table-flex-cell">
                        {
                          tournament?.pointSystem?.pointsPerPlacement![
                            Number(key)
                          ]
                        }
                      </div>
                    </div>
                  )
                )}
          </div>
        </>
      )}
    </div>
  );
};

export default PointSystemTab;
